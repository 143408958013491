import "../styles/LinkCard.css";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { LazyLoadImage } from "react-lazy-load-image-component";
import ButtonLink from "./ButtonLink";
export default function LinkCard(props) {
    return (<div className="link-card-container">
        <div className="link-image-container">
             <LazyLoadImage src={require("../images/" + props.folder + "/" + props.img_path)} placeholderSrc={require("../images/placeholders/"+ props.folder + "/" + props.img_path)} effect="blur" className='link-image' draggable="false"/>
        </div>
        <div className="link-card-content">
            {/* <button>{props.buttonText}</button>
            <h3 className="item-label-text">{props.buttonText}</h3> */}
            <p className="link-card-content-desc">
                {props.desc}
            </p>
            <div className="link-card-button-container">
            <ButtonLink link={props.link} text={props.buttonText} hash={props.hash}/>
            </div>
        </div>
    </div>
    );
}