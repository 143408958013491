import BulletList from "./BulletList";
import { CommercialPoints } from "../data/ListItems";
import { CarouselCommercialSlides } from "../data/CarouselSlides";
import "../styles/ServicePage.css";
import { SummaryCommercial } from "../data/Summaries";
import SummaryParagraphs from "./SummaryParagraphs";
import ButtonLink from "./ButtonLink";
import Carousel from "./Carousel";
import SlideGallery from "./SlideGallery";
export default function Commercial() {
    return (
        <div id="commercial">
            <div className="summary">
            <div className="services-banner">
                    {/* <img className="banner-image" src={require("../images/commercial/CommercialBanner2.jpg")}/> */}
                    <Carousel img_folder='carouselCommercial' slides={CarouselCommercialSlides} havetext/>
                </div>
                <h1 className="service-page-title">Commercial Services</h1>
                <SummaryParagraphs paragraphs={SummaryCommercial}/>
                <BulletList title='Treatment' items={CommercialPoints} />
                <SlideGallery title='Treatment' img_folder='carouselCommercial' slides={CarouselCommercialSlides} havetext/>
                <div className="anchor-button">
                    <ButtonLink link="/commercial" hash='#contact-section' text="Get a free quote!"/>
                </div>
            </div>
        </div>
    );
}