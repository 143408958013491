export const SectorsImages = [
    {
        name: 'Residential',
        img_path: 'Residential.jpg',
        url: 'residential',
        hash: '#residential'
    },
    {
        name: 'Commercial',
        img_path:'Commercial.jpg',
        url: 'commercial',
        hash: '#commercial'
    },
    // {
    //     name:'Industrial',
    //     img_path:'industrial-placeholder.png',
    //     url: 'industrial',
    //     hash: '#industrial'
    // }
]