export const CarouselHomeSlides = [
    {
        name: 'Slide #1',
        text: ' Text #1',
        img_path: 'HomeBanner3.jpg'
    },
    {
        name: 'Slide #2',
        text: ' Text #2',
        img_path: 'HomeBanner4.jpg'
    },
    {
        name: 'Slide #3',
        text: ' Text #3',
        img_path: 'HomeBanner_1.jpg'
    },
]

export const CarouselResidentialSlides = [
    {
        name: 'Retirement Homes',
        text: 'Retirement Homes',
        img_path: 'ResidentialBanner1.jpg'
    },
    {
        name: 'Condos',
        text: 'Condos',
        img_path: 'ResidentialBanner2.jpg'
    },
    {
        name: 'Apartments',
        text: 'Apartments',
        img_path: 'ResidentialBanner3.jpg'
    },
    {
        name: 'Homes',
        text: 'Homes',
        img_path: 'ResidentialBanner4.jpg'
    }    
]

export const CarouselCommercialSlides = [
    {
        name: 'Hotels/Motels',
        text: 'Hotels/Motels',
        img_path: 'CommercialBanner1.jpg'
    },
    {
        name: 'Distribution Centres',
        text: 'Distribution Centres',
        img_path: 'CommercialBanner2.jpg'
    },
    {
        name: 'Food Processing Units',
        text: 'Food Processing Units',
        img_path: 'CommercialBanner3.jpg'
    },
    {
        name: 'Grocery Stores',
        text: 'Grocery Stores',
        img_path: 'CommercialBanner4.jpg'
    },
    {
        name: 'Retail Stores',
        text: 'Retail Stores',
        img_path: 'CommercialBanner5.jpg'
    },
    {
        name: 'Restaurants',
        text: 'Restaurants',
        img_path: 'CommercialBanner6.jpg'
    }


]