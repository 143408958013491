import { useState } from "react"
import "../styles/TabContainer.css"
import SummaryParagraphs from "./SummaryParagraphs";
export default function TabContainer(props) {
const[currentTab, setCurrentTab] = useState(props.items[0].name);
const changeCurrent = (item) => {setCurrentTab(item.name)};
// const tabs = props.items.map (item => <button classname={currentTab === item.name? 'tab current': 'tab'} onClick={() => setCurrentTab(item.name)} key={item.name}>{item.name}</button>)
const currentTabContent = props.items.find(({name}) => name === currentTab).content;
const currentTabContentParagraphs = <SummaryParagraphs paragraphs={currentTabContent}></SummaryParagraphs>
return (
    <div className="tab-container">
        <div className="tabs">
            {props.items.map (item => <button type="button" className={currentTab === item.name? 'tab current': 'tab'} onClick={() => setCurrentTab(item.name)} key={item.name}>{item.name}</button>)}
        </div>
        <div className="tab-content">
        {currentTabContentParagraphs}
        </div>
    </div>
  )
}
