import FocusItem from "./FocusItem";
import "../styles/FocusGrid.css";
export default function FocusGrid({id, title, items}) {
    var focusItems = items.map(focusItem => <FocusItem item={focusItem} />);
    return (
        <div className='focus' id={id}>
            <h1 className='focus-title'>{title}</h1>
            <div className='focus-grid'>
                {focusItems}
            </div>
        </div>
    )
}