import { useEffect } from 'react';
import { MapContainer, Marker, Popup, TileLayer, Tooltip, useMap } from 'react-leaflet'
import "../styles/Map.css";

export default function Map(props) {

    return (
  <div id='map'>  
  <MapContainer center={[43.700840, -79.819610]} zoom={15} scrollWheelZoom={false}>
  <TileLayer
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  <Marker position={[43.700840, -79.819610]}>
  <Tooltip direction='up' closeButton='true' className='location-popup' interactive={true} permanent>
    Office Location:<br></br>
    16 River Rock Crescent <br></br>
    Brampton, Ontario <br></br>
    <a href='https://goo.gl/maps/2Pey2sZHoDq9nYiN7' style={{textDecoration: 'underline'}}> Get Directions</a>
  </Tooltip>
  </Marker>
  </MapContainer>
  </div>
    )
}
