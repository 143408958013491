import "../styles/PhoneCallout.css"
export default function PhoneCallout() {
    return <div id="phone-callout">
        <div className="phone-call-us-label-box">        
            <span className="phone-call-us-label">Call us at:</span>
        </div>
        <div className="phone-call-us-number-box">    
        <span className="phone-call-us-number">(416) 892-6262</span>
        </div>  
    </div>
}