export const FooterLinkItemsGroups = [
    {title: 'Useful Links',
     items: [
        {link: '/', hash: '#home', text: 'Home'},
        {link: '/about', hash:'#about', text:'About Us'},
        {link: '/residential', hash:"#residential", text:'Residential Services'},
        {link: '/commercial', hash:"#commercial", text:'Commercial Services'},
        {link: '/library', hash:"#library", text:'Pest Library'}
     ]
    },
    {title: 'Pests',
     items: [
        {link: '/library/rodents', hash:'#bug-info', text:'Rodents'},
        {link: '/library/bedBugs',hash:'#bug-info', text:'Bed Bugs'},
        {link: '/library/cockroaches',hash:'#bug-info', text:'Cockroaches'},
        {link: '/library/flies',hash:'#bug-info', text:'Flies'},
        {link: '/library/fleas',hash:'#bug-info', text:'Fleas'},
     ]
    },
    {
        items: [
            {link: '/library/ants',hash:'#bug-info', text:'Ants'},
            {link: '/library/carpenterAnts',hash:'#bug-info', text:'Carpenter Ants'},
            {link: '/library/pavementAnts',hash:'#bug-info', text:'Pavement Ants'},
            {link: '/library/pharoahAnts',hash:'#bug-info', text:'Pharoah Ants'},
            {link: '/library/earwigs',hash:'#bug-info', text:'Earwigs'}
        ]
    },
    {
        items: [
            {link: '/library/houseCentipedes',hash:'#bug-info', text:'House Centipedes'},
            {link: '/library/millipedes',hash:'#bug-info', text:'Millipedes'},
            {link: '/library/spiders',hash:'#bug-info', text:'Spiders'},
            {link: '/library/wasps',hash:'#bug-info', text:'Wasps'},
            {link: '/library/silverfish',hash:'#bug-info', text:'Silverfish'}
        ]
    }      
]
