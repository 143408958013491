import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import { Route, Routes } from "react-router-dom";
import AboutPage from './components/AboutPage';
import CardGrid from './components/Gallery';
import Gallery from './components/Gallery';
import Residential from './components/Residential';
import Commercial from './components/Commercial';
import PestLibrary from './components/PestLibrary';
import Industrial from './components/Industrial';
import PageNotFound from './components/PageNotFound';
import BugInformation from './components/BugInformation';
function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    <div className='App'>
      <Navbar />
      <div className='main'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<AboutPage/>} />
          <Route path='/residential' element={<Residential/>} />
          <Route path='/commercial' element={<Commercial/>}/>
          <Route path='/industrial' element={<Industrial/>}/>
          <Route path='/library' element={<PestLibrary/>}/>
          <Route path='/library/:bug' element={<BugInformation/>} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
