import "../styles/AboutPage.css";
import "../styles/ServicePage.css";
import { SummaryAboutList } from "../data/Summaries";
import { SummaryAbout } from "../data/Summaries";
import Objective from "./Objective";
import SummaryParagraphs from "./SummaryParagraphs";
import SummaryList from "./SummaryList";
import Logo from "./Logo";
export default function AboutPage(){
    return (
        <div id="about">
            <div className="summary">
                <h1 className="service-page-title">About Us</h1>
                <SummaryParagraphs paragraphs={SummaryAbout} />
                <SummaryList items={SummaryAboutList} />
                {/* <Logo></Logo> */}
                <div className="motto-container">
                <span className="motto-text">Find Your Pest Solutions with Trident!</span>
                </div>
            </div>
            {/* <div id="slogan">
            FIND YOUR PEST SOLUTIONS WITH TRIDENT!    
            </div> */}
            {/* <Objective name='Our Mission' seperator=':' description='Our mission is to give you a thorough understanding of the problems you may face and an easy and reliable/affordable solution.'></Objective>
            <Objective name='Our Vision' seperator=':' description='Our vision is “Pest Control Made Easy”'></Objective>        */}
        </div>
    );
}
