import ButtonLink from "./ButtonLink";
import "../styles/ServicePage.css";
export default function Industrial() {
    return <div id="industrial">
                <div className="summary">
                <h1 className="service-page-title">Industrial Services</h1>
                {/* <SummaryParagraphs paragraphs={SummaryCommercial}/> */}
                <div className="anchor-button">
                    <ButtonLink link="/industrial" hash='#contact-section' text="Get a free quote!"/>
                </div>
            </div>
    </div>
}