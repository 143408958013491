import SummaryParagraphs from "./SummaryParagraphs";
import { CarouselResidentialSlides } from "../data/CarouselSlides";
import { SummaryResidential } from "../data/Summaries";
import { SummaryResidentialTreatment } from "../data/Summaries";
import { SummaryResidentialABCTabs } from "../data/Summaries";
import { SummaryResidentialAlpha } from "../data/Summaries";
import { SummaryResidentialBravo } from "../data/Summaries";
import { SummaryResidentialCharlie } from "../data/Summaries";
import { ListItemResidentialTreatment } from "../data/ListItems";
import BulletList from "./BulletList";
import OrderedList from "./OrderedList";
import ButtonLink from "./ButtonLink";
import Carousel from "./Carousel";
import "../styles/ServicePage.css"
import TabContainer from "./TabContainer";
import Gallery from "./Gallery";
import SlideGallery from "./SlideGallery";

export default function Residential() {
    return (
        <div id="residential">
            <div className="summary">
                <div className="services-banner">
                    {/* <img className="banner-image" src={require("../images/residential/ResidentialBanner2.jpg")}/> */}
                    <Carousel img_folder='carouselResidential' slides={CarouselResidentialSlides} havetext/>
                </div>
                <h1 className="service-page-title">Residential Services</h1>
                <SummaryParagraphs paragraphs={SummaryResidential} />
                <SummaryParagraphs paragraphs={SummaryResidentialTreatment} />
                <BulletList title="Treatment" items={ListItemResidentialTreatment} />
                <SlideGallery title='Treatment' img_folder='carouselResidential' slides={CarouselResidentialSlides} havetext/>
                <h2 className="service-page-subtitle">Picking a residential plan is as easy as A.B.C!</h2>
                {/* <h3 className="service-page-item">Alpha Plan</h3>
                <SummaryParagraphs paragraphs={SummaryResidentialAlpha} /> 
                <h3 className="service-page-item">Bravo Plan</h3>
                <SummaryParagraphs paragraphs={SummaryResidentialBravo} />
                <h3 className="service-page-item">Charlie Plan</h3>
                <SummaryParagraphs paragraphs={SummaryResidentialCharlie} /> */}
                <TabContainer items={SummaryResidentialABCTabs} />
                
                <div className="anchor-button">
                    <ButtonLink link="/residential" hash='#contact-section' text="Get a free quote!"/>
                </div>
            </div>
        </div>
    );
}