export const LocationsItems = [
    'Toronto',
    'Etobicoke',
    'Peel',
    'Brampton',
    'Caledon',
    'Vaughan',
    'Mississauga',
    'Milton',
    'Oakville',
    'Halton Hills',
    '...and more!'
]