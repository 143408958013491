export const CommercialPoints = [
    'Restaurants',
    'Retail stores',
    'Distribution centres',
    'Hotel/Motels',
    'Convenience stores',
    'Food processing Units',
    'Grocery Stores',
]

export const ListItemResidentialTreatment = [
    'Home',
    'Apartment',
    'Condos',
    'Retirement Homes',
]

// Link the pest library lists here