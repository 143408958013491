import Map from "./Map";
import Gallery from './Gallery';
import Logo from "./Logo";
import '../styles/Home.css'
import Carousel from "./Carousel";
import { CarouselHomeSlides } from "../data/CarouselSlides";
import CarouselGallery from "./CarouselGallery";
import { PestLibraryData } from "../data/PestLibraryData"
import { SectorsImages } from "../data/SectorsImages";
import { SpecializationItems } from "../data/SpecializationItems";
import QueryContact from "./QueryContact";
import { SummaryHome, SummaryHomeAbout, SummaryHomeContact, SummaryHomeResidential }  from "../data/Summaries";
import SummaryParagraphs from "./SummaryParagraphs";
import ButtonLink from "./ButtonLink";
import Introducer from "./Introducer";
import BackgroundList from "./BackgroundList";
import { LocationsItems } from "../data/LocationsItems";
import CallOut from "./CallOut";
import ActionBanner from "./ActionBanner";
import LinkCard from "./LinkCard";
import FocusGrid from "./FocusGrid";
export default function Home() {
    return <div id="home">
        {/* <section id="banner">
            <Carousel img_folder='carouselHome' slides={CarouselHomeSlides} />
            <div className="home-logo">
                <Logo background></Logo>
                <div className="motto-container">
                <span className="motto-text">Our Motto: Find Your Pest Solutions with Trident!</span>
                </div>
            </div>
        </section> */}
        <ActionBanner/>
        <div className="summary">
            <h1>Welcome to the Home of Quality Pest Control Services!</h1>
            <SummaryParagraphs paragraphs={SummaryHome} />
            <div id="home-link-cards" >
            <LinkCard desc={SummaryHomeAbout} link="/about/" buttonText="Learn more" hash="#about" folder="homeLinks" img_path="Professional.jpg"/> 
            <LinkCard desc={SummaryHomeResidential} link="/residential" buttonText="Learn more" hash="#residential" folder="homeLinks" img_path="Residential.jpg"/>
            </div>
            {/* <SummaryParagraphs paragraphs={SummaryHomeContact} />
            <div className="anchor-button">
            <ButtonLink link="/" hash='#contact-section' text="Contact us now"/>
            </div> */}
        </div>
        {/* <CarouselGallery folder='library' items={PestLibraryData} title='Pests'></CarouselGallery> */}
        <FocusGrid id="focus-specializations" title="Our Specializations" items={SpecializationItems}/>
        <section className="location">
            <Introducer text="We are conveniently located to serve you all across the GTA!" />
            <BackgroundList items={LocationsItems} title="Locations We Serve"/>
        </section>
        <Gallery id='sectors' title='Sectors We Serve' folder='sectors' items={SectorsImages} />

        <section id="query">
        <CallOut id="callout-query" text="Have a question or comment? Let us know!"></CallOut>
        {/* <QueryContact id="home-query" /> */}
        </section>
        <section id="small-screen-only">
        <CallOut id="callout-quote" text="Or you can request a free quote from us!"></CallOut>     
        </section>
        </div>
}