import "../styles/GalleryItem.css";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function GalleryItem(props) {
    return (<div className="item-container">
        <div className="item-image-container">
            { (props.item.url || props.item.absoluteUrl) && !RegExp("library\/.").test(window.location.pathname)?
             <HashLink to={(props.item.absoluteUrl? props.item.absoluteUrl: props.item.url) + (props.item.hash? props.item.hash: "")}>   
            {/* <img src={require("../images/"+ props.folder + "/" + props.item + '.jpg')} className='item-image' href={props.url} /> */}
            {/* <img src={require("../images/" + props.folder + "/" + props.item.img_path)} className='item-image' /> */}
            <LazyLoadImage src={require("../images/" + props.folder + "/" + props.item.img_path)} placeholderSrc={require("../images/placeholders/"+ props.folder + "/" + props.item.img_path)} effect="blur" className='item-image' draggable="false"/>
            </HashLink>
             :
             <LazyLoadImage src={require("../images/" + props.folder + "/" + props.item.img_path)} placeholderSrc={require("../images/placeholders/"+ props.folder + "/" + props.item.img_path)} effect="blur" className='item-image' draggable="false"/>
             }
        </div>
        {/* <br></br> */}
        <div className="item-label">
            <h3 className="item-label-text">{props.item.name}</h3>
        </div>
    </div>
    );
}