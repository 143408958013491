import { useState } from "react"
import {BsArrowLeftSquareFill, BsArrowRightSquareFill} from 'react-icons/bs'
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../styles/Carousel.css"
export default function Carousel(props) {
    const [currentState, setState] = useState(0);
    // const images = require("../images/" + props.img_folder);
    const changeSlideNext = () => {
        if(currentState == props.slides.length - 1) {
            setState(0);
        }
        else {
            setState(currentState + 1);
        }
    }

    const changeSlidePrevious = () => {
        if(currentState == 0) {
            setState(props.slides.length - 1);
        }
        else {
            setState(currentState - 1);
        }
    }
    return <div className="carousel">
        <BsArrowLeftSquareFill className="arrow-left" onClick={changeSlidePrevious} />
        {props.slides.map ((slide, i) =>
            <div className={i == currentState? 'slide active': 'slide'} key='i'>
               {i == currentState && (<div className="slide-img-container">
                {/* <img src={require("../images/" + props.img_folder + "/" + slide.img_path)} className='slide-img'/> */}
                <LazyLoadImage src={require("../images/" + props.img_folder + "/" + slide.img_path)} placeholderSrc={require("../images/placeholders/"+ props.img_folder + "/" + slide.img_path)} effect="blur" className='slide-img' draggable="false"/>
               {props.havetext && <div className="slide-text">{slide.text}</div>}
                </div>)}
            </div>
            )
        }
                <BsArrowRightSquareFill className="arrow-right" onClick={changeSlideNext} />
    </div>
}