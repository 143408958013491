import "../styles/Summary.css"
export default function SummaryParagraphs(props){
//     const renderInternalSummaryComponent = (componentString) => {
//         console.log(true);
//         if(RegExp('^TITLE:').test(componentString)){
//             return <h3>{componentString.substring(6)}</h3>
//         }
//     }
    // const components = props.components.map(component =>
    //     component.includes("BUTTON:") || component.includes("TITLE:")? renderInternalSummaryComponent(component): <p className="paragraph">{component}</p>);
    const paragraphs = props.paragraphs.map(component => <p className="paragraph">{component}</p>)
    return (
            <div className="summary-paragaphs">
            {paragraphs}
            </div>
    );
}