export const PestLibraryData = [
    {
        name: 'Rodents',
        url: 'rodents',
        absoluteUrl: '/library/rodents',
        hash: '#bug-info',
        img_path: 'Rodents.jpg',
        list: {
          title: 'Signs of Infestation',  
          items : [
            {
            title: 'Rodent Dropping',
            subItems: ['Rodent droppings can be found in areas containing food items necessary for their survival. These areas include, near food packages, inside of drawers or Cupboards, under sinks and hidden areas.']
            },
            {
            title: 'Animal Gnawing',
            subItems: ['These will often be found on food packaging or the structure of the house itself. The marks can also indicate whether you have rats or mice; larger gnaw marks will have been produced by the larger teeth of rats. And vice versa.'],
            },
            {
            title: 'Foul Odor',
            subItems: ['Pets such as dogs and cats will often become eccentric near areas where rodents are present. This is in large parts due to the fact that pets are more sensitive to the scents that rodents bring in with them upon entering a structure. If an infestation has become large you may smell a faint stale smell coming from hidden areas, indicating that you may have an active infestation.'],
            },
            {
            title: 'Tracks and Runaways',
            subItems: ['If rodents are currently active in or around your house/ establishment, their tracks are quite distinctive. However, as time passes you may find these tracks to fade away or become a lot fainter. Smudge marks, footprints, urine stains, or droppings are all associated to a possible infestation in your property.'],
            },
            {
            title: 'Nest',
            subItems: ['Shredded paper, dried plant matter or fabric may often be used by rodents to make their nests and nurse their young. Droppings, footprints, gnaw marks, odor or tracks are all signs of a still active infestation.'],
            },
            {
            title: 'Rodent Population',
            subItems: ['Many signs can indicate how big a population has gotten over a period. If rats and mice can be seen only at night but not during the day, then the population has not gotten out of control. Therefore, it can be controlled by traps and bait. However, if fresh droppings are seen during the day and new gnaw marks have appeared, then it is highly likely that the population has gotten large and requires professional service.'],
            },
        ]
        }
    },
    {
        name: 'Bed Bugs',
        url: 'bedBugs',
        absoluteUrl: '/library/bedBugs',
        hash: '#bug-info',
        img_path:'bed-bugs.gif',
        list: {
            title: 'Signs of Infestation',
            items: [
                'Blood stains on Sheets and pillowcase',
                'Dark or rusty spots of bedbug excrement on sheets and mattresses, clothes and walls',
                'Bedbug fecal spots, egg shells, or sheds of skin in areas where they tend to hide. ',
                'A musty, aggressive odor from bugs’ scent glands'
            ]
        }
    },
    {
        name:'Cockroaches',
        url: 'cockroaches',
        absoluteUrl: '/library/cockroaches',
        hash: '#bug-info',
        img_path:'cockroches.jpg',
        species: [
            {
                name: 'German Cockroaches',
                img_path: 'americanCockroach.jpg',
                summary: ['German cockroaches can become a major problem in kitchens, restaurants, pubs, cafes and in our homes. They can live in refrigerators and in ovens and can hide among the smallest cracks and or crevices. They are prolific breeders and can be located easily due to their light brown color and two black stripes on their thoracic shield located directly behind their head.'],
                source: ''
            },
            {
                name: 'Oriental Cockroaches',
                summary: ['Oriental Cockroaches they are often referred to as water bugs because they prefer to hide out in cool dark and damp areas. Such as under washing machines very damp basements and sinks. They tend to live in filth and often travel through sewer pipes. Almost one inch in length they are known for their brown almost black bodies. They feed on garbage and decaying matter and can be found in kitchens and food preparation areas.'],
                source: 'http://ento.psu.edu/extension/factsheets/oriental-cockroaches'
            },
            {
                name: 'Brown Banded Cockroaches',
                summary: ['Brown Banded Cockroaches they are known as the smallest and the most evasive of cockroaches. They prefer a warmer and dry climate. They live near refrigerators, on the upper walls of cabinets, and inside and around pantries and other furniture. Bacteria and protozoa that cause diseases (such as different forms of gastroenteritis and diarrhea) can be carried on the legs and bodies of cockroaches and deposited on food, utensils.'],
                source: 'http://ento.psu.edu/extension/factsheets/brown-banded-cockroaches'
            },
            {
                name: 'American Cockroaches',
                summary: ['American Cockroaches often are abundant in dumps and are most commonly found in basements and steam tunnels of restaurants, bakeries, food processing facilities and grocery stores. They are the largest of their species.'],
                source: 'http://www.pestworld.org/pest-guide/cockroaches/american-cockroaches/'
            }
        ]
    },
    {
        name:'Flies',
        url: 'flies',
        absoluteUrl: '/library/flies',
        hash: '#bug-info',
        img_path:'Flies.jpg',
        species: [
            {
                name: 'Fruit Flies',
                summary: ['Fruit Flies Are common pests in houses, restaurants and grocery stores where ever there may be an ample amount of fermenting foods. They are plentiful near ripened fruit and vegetables. They will also breed in empty bottles and cans, trash bins, drains, mops, and cleaning rags.'],
                source: 'https://entomology.ca.uky.edu/ef621'
            },
            {
                name: 'Cluster Flies',
                summary: ['Cluster Flies they are slightly larger than the average house fly and have are dull grey with black markings and appear to have an almost golden sheen.'],
                source: 'https://www.canada.ca/en/health-canada/services/pest-control-tips/clustering-flies.html'
            },
            {
                name: 'House Flies',
                summary: ['House Flies while not the cleanest of insects these insects can be most commonly found in your homes and businesses. They tend to visit places such as dumps, garbage heaps and sewers.  They are major carriers of many different types of diseases such as tuberculosis, dysentery, and cholera plus 100’s of more pathogens.']
            }
        ]
    },
    {
        name:'Fleas',
        url: 'fleas',
        absoluteUrl: '/library/fleas',
        hash: '#bug-info',
        img_path:'Fleas.jpg'
    },
    {
        name:'Ants',
        url: 'ants',
        absoluteUrl: '/library/ants',
        hash: '#bug-info',
        img_path:'Ants.jpg'
    },
    {
        name:'Carpenter Ants',
        url: 'carpenterAnts',
        absoluteUrl: '/library/carpenterAnts',
        hash: '#bug-info',
        img_path:'carpenter-ant.jpg'
    },
    {
        name:'Pavement Ants',
        url: 'pavementAnts',
        absoluteUrl: '/library/pavementAnts',
        hash: '#bug-info',
        img_path:'Pavement-Ants.jpg'
    },
    {
        name:'Pharoah Ants',
        url: 'pharaohAnts',
        absoluteUrl: '/library/pharaohAnts',
        hash: '#bug-info',
        img_path:'PharaohAnts.jpg'
    },
    {
        name:'Earwigs',
        url: 'earwigs',
        absoluteUrl: '/library/earwigs',
        hash: '#bug-info',
        img_path:'Earwigs.jpg'
    },
    {
        name:'House Centipedes',
        url: 'houseCentipedes',
        absoluteUrl: '/library/houseCentipedes',
        hash: '#bug-info',
        img_path:'House-centipede.jpg'
    },
    {
        name:'Millipedes',
        url: 'millipedes',
        absoluteUrl: '/library/millipedes',
        hash: '#bug-info',
        img_path:'Millipedes.jpg'
    },
    {
        name:'Spiders',
        url: 'spiders',
        absoluteUrl: '/library/spiders',
        hash: '#bug-info',
        img_path:'Spiders.jpg'
    },
    {
        name:'Wasps',
        url: 'wasps',
        absoluteUrl: '/library/wasps',
        hash: '#bug-info',
        img_path:'Wasps.jpg'
    },
    {
        name:'Silverfish',
        url: 'silverfish',
        absoluteUrl: '/library/silverfish',
        hash: '#bug-info',
        img_path:'Silverfish.jpg'
    }
]