import "../styles/Navbar.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { HiBars3, HiBarsArrowUp } from "react-icons/hi2";
import Logo from './Logo';
import PhoneCallout from "./PhoneCallout";
export default function Navbar() {
  const onSmallButtonClick = () => {
    setLinksOpen(!linksOpen);

  }
  const onSmallLinkClick = () => {
    onSmallButtonClick();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    function renderOnResize() {
      window.innerWidth <= 1280 ? setSmallScreen(true) : setSmallScreen(false);
    }

    window.addEventListener('resize', renderOnResize);
  })
  const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 1280);
  const [linksOpen, setLinksOpen] = useState(false);

  return <nav className="navbar">
    <div className="nav-top">
    <div className="navbar-logo">
      {window.innerWidth > 1280 ? <Link to="/" className="nav-logo-container"><Logo /></Link> : <div className="nav-logo-container"><Logo /></div>}
    </div>
    <div className="navbar-small-button" onClick={onSmallButtonClick}>
      {smallScreen & linksOpen ? <HiBarsArrowUp /> : <HiBars3 />}
    </div>
    {!smallScreen && <PhoneCallout />}
    </div>

    <br/>
    <ul className={smallScreen ? "navbar-small-links" + (linksOpen ? "-open" : "") : "navbar-links"}>
      <li className="navbar-link" onClick={linksOpen ? onSmallLinkClick : null}>
        <Link to="/">Home</Link>
      </li>
      <li className="navbar-link" onClick={linksOpen ? onSmallLinkClick : null}>
        <Link to="/about"> About Us</Link>
      </li>
      <li className="navbar-link" onClick={linksOpen ? onSmallLinkClick : null}>
        <Link to="/residential">Residential</Link>
      </li>
      <li className="navbar-link" onClick={linksOpen ? onSmallLinkClick : null}>
        <Link to="/commercial"> Commercial</Link>
      </li>
      {/* <li>
            <Link to="/industrial">Industrial</Link>
            </li> */}
      <li className="navbar-link" onClick={linksOpen ? onSmallLinkClick : null}>
        <Link to="/library">Pest Library</Link>
      </li>
    </ul>

  </nav>
}