export const SummariesLibrary = [
    {
        url: 'rodents',
        paragraphs: ['Rodents (rats & mice) are a serious issue that affects many residential, commercial and industrial facilities. Our homes are very attractive to them, as they provide them with everything they need: food, water and shelter. Not only do they spread diseases and contaminate food, they also cause damage to your property. Rodents must continually gnaw on their teeth to keep them from growing too long. They can cause serious structural damage to homes, apartments, offices, and virtually any type of building through gnawing, nest-building, and defecation. Their population can reach over a few hundred in less than 30 days. '],
        source: ['http://npic.orst.edu/pest/rodent.html']
    },
    {
        url:'bedBugs',
        paragraphs: ['Wingless Insects, bed bugs are oval shaped and 5-6mm in length as adults. Rusty brown in color, changing to a deep red after a meal of blood. An Adult bedbug can live for 6 months at room temperature and much longer in colder climates. They are usually found in hard to see and reach places. Crevices and crack, including bed sheets, mattress seams, furniture, inside of base boards and finally electrical outlets are some places to check if doubt arises of bed bug infestation. Usually any place people tend to gather bed bugs can often be found, these include homes, hotels, schools, offices, retail stores and even public transportation; they travel easily and are transported via luggage, clothing and bedding. '],
        source:['http://npic.orst.edu/pest/bedbug/index.html']
    },
    {
        url:'cockroaches',
        paragraphs: ['	Cockroaches are one of the most common pests in Canadian homes and businesses. They favor conditions like those that humans create, which is why effective cockroach control and cockroach management in Canada is important. They are carriers of various diseases, and can be found in kitchens where food is plentiful or near waste. Cockroaches can be a nuisance as they can contaminate food, utensils and preparation areas. They leave behind droppings, cast skins, egg sacs, foul odor and more.'],
    },
    {
        url:'flies',
        paragraphs: ['Feed by vomiting on their food source before eating. They are the number one pest in domestic and commercial settings, as they feed on decaying matter, feces, garbage, and carcasses.'],
        source:['http://ipm.ucanr.edu/PMG/PESTNOTES/pn7457.html']
    },
    {
        url: 'fleas',
        paragraphs: ['Fleas can be the nightmare of any pet owner. However not attending to the problem at the right time can lead to an infestation in your household, which cannot just effect your pet but you also. Flea commonly ten to feed on hairy animals. Some of the animals include Dogs, Cats, Rabbits Mice, Squirrels and rats.'],
        source: ['http://www.pestworld.org/pest-guide/fleas/flea/']
    },
    {
        url: 'ants',
        paragraphs: ['Ants cam become a nuisance when the go foraging for food. Quite often while searching they will end up in your home or business establishment. When searching they can be attracted to a wide range with different species preferring meat, sweets, fats and oils. Out of all the species that nest and feed in establishments, Ants are among the most common, with each species varying in terms of nesting sites, feeding habits and preferences.']
    },
    {
        url: 'carpenterAnts',
        paragraphs: ['Carpenter ants tend to feed on other various pests that are beneficial to their given habitat. The ant indoors tends to nest in areas where the humidity and dampness has created softened wood. These include place like behind wooden panel doors, around wooden panels and dishwashers and moist areas behind fireplaces, baseboards and windows.'],
        source: ['http://www.pestworld.org/pest-guide/ants/carpenter-ants/']
    },
    {
        url: 'pavementAnts',
        paragraphs: ['This ant is the most common house infesting ant in North America. They tend to swarm in the spring but they will emerge at any time where there is warm heating. They feed on all kinds of foods including sugars fruits nectars and all types of syrups. Any food that is dropped on the ground has the possibility of being consumed by the pavement ant. '],
        source: ['http://www.pestworld.org/pest-guide/ants/pavement-ants/']
    },
    {
        url: 'pharaohAnts',
        paragraphs: ['Whether in your home grocery store or your business ant infection can be nuisance. They are very tiny and quick ants they prefer to nest indoors and are prolific breeders. With protected nests their nest can be found hidden around an establishment mostly in your garden.'],
        source: ['https://myhealth.alberta.ca/alberta/pages/pests-pharaoh-ants.aspx']
    },
    {
        url: 'earwigs',
        paragraphs: ['There are twenty well known species of Earwigs in North America. Many species produce a strong foul-smelling liquid that is used as a defense mechanism. They are known to produce pheromones that cause all earwigs to cluster together in large numbers. Because of the pincers on the back of their abdomen, many people are frightened by them. However, those pincers are used for nothing but defense and for sparing with rival earwigs.'],
        source: [' http://ipm.ucanr.edu/PMG/PESTNOTES/pn74102.html']
    },
    {
        url: 'houseCentipedes',
        paragraphs: ['All centipedes have poisoned glands, where bites can cause central temporary localized pains. Its best that if you come across these creatures you avoid any direct contact to minimize any chance of a bite. Adults tend to hide in dark moist and damp areas that are secluded. Found under boards, logs, rocks, and protected damp locations outside.'],
        source: ['http://ento.psu.edu/extension/factsheets/house-centipedes']
    },
    {
        url:'millipedes',
        paragraphs:['Millipedes are wormlike arthropods found throughout North America. Found under rocks, leaves, boards, and other locations that provides cool shade and damp habitat. Can be easily identified by their two pairs of legs per body segment. They can enter through basement door, windows, vents and different crawl spaces.'],
        source:['http://ipm.ucanr.edu/PMG/PESTNOTES/pn7472.html']
    },
    {
        url:'spiders',
        paragraphs:['Spiders come in various sizes and colors. They have eight legs, two body regions with no wings and antennae. With many of them liking moisture, they are commonly found in basements, crawl spaces and any damp place of a building.'],
        source:['http://www.pestworld.org/pest-guide/spiders/']
    },
    {
        url:'wasps',
        paragraphs:['With many known species of wasps, the yellow jacket is the most common in North America. Less than 2 cm in length, they come in various colors. Wasps are an nuisance and can be dangerous, they can sting if provoked. Wasps tend to feed on sweet substances and other small insects.'],
        source:['http://www.pestworld.org/news-hub/pest-articles/interesting-facts-about-mosquitoes-wasps-and-other-common-summer-pests/']
    },
    {
        url:'silverfish',
        paragraphs:['Tiny bugs usually found in kitchens, laundry rooms, bathrooms and dark isolated areas within homes. Commonly found in boxes, garages and sheds, they are attracted to paper and damp clothing.'],
        source:['https://www.canada.ca/en/health-canada/services/pest-control-tips/silverfish-firebrats.html']
    }
]