export default function BackgroundList(props) {
    // const img_path = "../images/" + props.img_path;
    // const img = require("../images/" + props.img_path);

    const items = props.items.map(item => <span className="background-list-item">{"• " + item}</span>);
    return (
        <div className="background-list-container" >
            <div className="background-list">
            {/* <h3 className="list-title">{props.title}</h3> */}
            <div className="background-list-items">{items}</div>
            </div>
        </div>
    );
}

//  style={{backgroundImage: `url(${require(img_path)})` }}
//style={{backgroundImage: `url(${img_path})` }}