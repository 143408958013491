import Map from "./Map";
import "../styles/Footer.css";
import Contact from "./Contact";
import { FooterLinkItemGroups } from "../data/FooterLinkItemGroups";
import FooterInformation from "./FooterInformation";
export default function Footer() {
  // const footerLinkList =
    return <div id="footer">
              <Contact />     
             <Map />
             <FooterInformation/>
           </div>
}