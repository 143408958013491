import { FooterLinkItemsGroups } from "../data/FooterLinkItemGroups";
import LinkList from "./LinkList";
import Logo from "./Logo";
export default function FooterInformation(props) {
    const FooterLinkItemsLists = FooterLinkItemsGroups.map(group => <LinkList title={group.title} items={group.items}/>);
    return (
        <div id="footer-info">
            <div id="footer-info-body">
            <div id="footer-links">
                {FooterLinkItemsLists}
            </div>
            <div id="contact-info">
                <h3 id="contact-us-title">Contact Us</h3>
                <div id="contact-address">
                <div className="contact-info-block">
                <span className="contact-label">Address:</span>
                <span className="contact-line">16 River Rock Crescent</span>
                <br/>
                <span className="contact-line">Brampton, Ontario L7A 2V2</span>
                </div>
                </div>
                <div id="contact-phone">
                <div className="contact-info-block">
                <span className="contact-label">Phone Number:</span>
                <span className="contact-line">(416) 892-6262</span>
                </div>
                </div>
            </div>
            <Logo></Logo>
            </div>
        </div>
    )
}