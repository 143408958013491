import "../styles/List.css"
export default function  BulletList(props) {
    const renderInternalSummaryComponent = (item) => {
        const subItems = item.subItems.map(subItem => <p className="list-item-subitem">{subItem}</p>)
        return <li className="list-item">{item.title && <h4 className="list-item-title">{item.title}</h4>}{subItems}</li>
    }
    // const items = props.items.map(item => item.item.map(subItem => <li className="list-item-text">{subItem}</li>));
    const items = props.items.map(item => typeof item === 'object'? renderInternalSummaryComponent(item): <li className="list-item">{item}</li>);
    // console.log(items)
    return (
        <div className="bullet-list">
            <h3 className="list-title">{props.title}</h3>
            <span className="list-context">{props.context}</span>
            <ul>{items}</ul>
        </div>
    )
}