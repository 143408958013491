import "../styles/ActionBanner.css"
import QueryContact from "./QueryContact"
export default function ActionBanner() {
    return (
        <div className="action-banner">
            <div className="action-banner-container" >
                <div className="action-banner-body">
                    <div className="action-banner-text">
                        <div className="action-banner-text-title">
                            <span className="action-banner-text-title-normal">YOUR LOCAL</span>
                            <span className="action-banner-text-title-bold">PEST CONTROL</span>
                            <span className="action-banner-text-title-normal">EXPERTS</span>
                        </div>
                        <div className="action-banner-text-subtitle">
                            <span className="action-banner-text-subtitle-normal">WE ARE HERE TO </span>
                            <span className="action-banner-text-subtitle-bold">RESOLVE </span>
                            <span className="action-banner-text-subtitle-normal">THE MOST COMPLEX ISSUE!</span>
                        </div>
                    </div>
                    <div className="bug-svg">
                        <img src={require("../images/banners/bug-flipped.png")} draggable="false"/>
                    </div>
                </div>
                <QueryContact id="home-query"></QueryContact>
            </div>
        </div>
    )
}