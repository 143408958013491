import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
export default function LinkList(props) {
    const footerLinkItems = props.items.map(item => <li className="link-list-item">
                {item.hash?
        <HashLink to={"" + item.link + item.hash}>{item.text}</HashLink>
        :
        <Link to={item.link}>{item.text}</Link>}
    </li>)
    return <div className="link-list">
        <h3 className="link-list-title">{props.title}</h3>
        <ul className="link-list-items">
            {footerLinkItems}
        </ul>
    </div>
}

// <Link to={item.link}>{item.text}</Link>