import GalleryItem from './GalleryItem';
import "../styles/Gallery.css";
export default function Gallery(props) {
    var galleryItems = props.items.map(galleryItem => <GalleryItem folder={props.folder} item={galleryItem}></GalleryItem>);
    return (
        <div className='gallery' id={props.id}>
            <h1 className='gallery-title'>{props.title}</h1>
            <div className='gallery-grid'>
                {galleryItems}
            </div>
        </div>
    )
}