import { LazyLoadImage } from "react-lazy-load-image-component";
import "../styles/Logo.css";
import 'react-lazy-load-image-component/src/effects/blur.css';
export default function Logo(props) {
    return <div className="logo-img-container">
        {props.background?
        //  <img src={require("../images/TridentLogo.jpg")} alt="Trident Pest Logo" className="logo-img"/>:
        <LazyLoadImage src={require("../images/TridentLogo.jpg")} alt="Trident Pest Logo" placeholderSrc={require("../images/placeholders/TridentLogo.jpg")} effect="blur" className="logo-img" draggable="false"/>:
        // <img src={require("../images/TridentLogo-noBackground.png")} alt="Trident Pest Logo" className="logo-img"/>}
        <LazyLoadImage src={require("../images/TridentLogo-noBackground.png")} alt="Trident Pest Logo" placeholderSrc={require("../images/placeholders/TridentLogo-noBackground.png")} effect="blur" className="logo-img" draggable="false"/>} 
            
            </div>
}