import { useParams } from "react-router-dom";
import GalleryItem from "./GalleryItem";
import { PestLibraryData } from "../data/PestLibraryData";
import { SummariesLibrary } from "../data/SummariesLibrary";
import "../styles/GalleryItem.css";
import "../styles/BugInformation.css"
import SummaryParagraphs from "./SummaryParagraphs";
import BulletList from "./BulletList";
import SpeciesInformation from "./SpeciesInformation";
import Source from "./Source";
export default function BugInformation(props) {
    const { bug } = useParams();
    const bugItem = PestLibraryData.find(({url}) => url === bug)
    const bugSummary = SummariesLibrary.find(({url}) => url === bug)
    //console.log(bugSummary)
    const bugSpecies = (item) => {
        var species = null;
        if(item.species) {
            // console.log(item.species);
            species = bugItem.species.map(type => <SpeciesInformation species={type} /> )
        }
        // console.log(species.toString())
        return species;
    } 
    const bugSources = (item) => {
        var sources = null;
        if(item.source){
            sources = bugSummary.source.map(source => <Source source={source} />);
        }
        console.log(sources);
        return sources;
    }
    return (
        <div id="bug-info">
        <GalleryItem folder='library' item={bugItem} />
        <div className="summary">
            <SummaryParagraphs paragraphs={bugSummary.paragraphs} />
            {bugItem.species && bugSpecies(bugItem)}
            {bugItem.list && <BulletList title={bugItem.list.title} items={bugItem.list.items} />}
            {bugSummary.source && bugSources(bugSummary)}
        </div>

        {/* <div className="summary">
        <SummaryParagraphs paragraphs={bugSummary.source} />
        </div> */}
        </div>
    )
}