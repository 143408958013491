import { useState } from "react"
import "../styles/Contact.css"
import axios from 'axios'
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { ServiceTypesItems, BugTypesItems, DurationLengthsItems } from "../data/ContactDropdownItems"
export default function Contact() {
    const initialState = {
        name: '',
        email: '',
        phone: '',
        city: '',
        service: 'Residential',
        bug: 'Rodents',
        duration: '0-1 weeks',
        body: ''
    }
    const [currentState, stateSetter] = useState(initialState);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios({
            method: "POST",
            url: "http://tridentpest.ca/send",
            // url: "http://localhost:3002/send",
            data: currentState
        }).then((response) => {
            if (response.data.status === 'success') {
                alert("Your message has been sent.");
            } else if (response.data.status === 'fail') {
                alert("Sorry, your message has not been sent. Please try again later.")
            }
        })
    }

    const mapItemsToOptions = (items) => {
        return items.map((item => <option value={item}>{item}</option>))
    }

    const onChangeName = (e) => {
        stateSetter({ ...currentState, name: e.target.value });
    }
    const onChangeEmail = (e) => {
        stateSetter({ ...currentState, email: e.target.value });
    }
    const onChangePhone = (e) => {
        stateSetter({ ...currentState, phone: e.target.value });
    }
    const onChangeCity = (e) => {
        stateSetter({ ...currentState, city: e.target.value });
    }
    const onChangeService = (e) => {
        stateSetter({ ...currentState, service: e.target.value });
        //console.log(currentState);
    }
    const onChangeBug = (e) => {
        stateSetter({ ...currentState, bug: e.target.value });
        //console.log(currentState);
    }
    const onChangeDuration = (e) => {
        stateSetter({ ...currentState, duration: e.target.value });
        //console.log(currentState);
    }
    const onChangeBody = (e) => {
        stateSetter({ ...currentState, body: e.target.value });
    }

    const location = useLocation();
    useEffect(() => {
        if(RegExp("commercial").test(window.location.pathname)) {
            stateSetter({ ...currentState, service: 'Commercial' });
        }
        else if (RegExp("residential").test(window.location.pathname) || RegExp("home").test(window.location.pathname)) {
            stateSetter({ ...currentState, service: 'Residential' });
        }
        else if (RegExp("industrial").test(window.location.pathname)) {
            stateSetter({ ...currentState, service: 'Industrial' });
        }
      }, [location]);
    // console.log(currentState);
    // console.log(location)
    return (
        <div id="contact-section">
            <div className="quote-text"> Request a free quote!</div>
            <form className="contact" method="POST" onSubmit={handleSubmit}>
                <div className="contact-field">
                    <label className="field-label" htmlFor="name">Name</label>
                    {/* <br></br> */}
                    <input type="text" className="field" value={currentState.name} onChange={onChangeName} />
                </div>
                <div className="contact-field">
                    <label className="field-label" htmlFor="email">Email address</label>
                    {/* <br></br> */}
                    <input type="email" className="field" value={currentState.email} onChange={onChangeEmail} />
                </div>
                <div className="contact-field">
                    <label className="field-label" htmlFor="phone">Phone</label>
                    {/* <br></br> */}
                    <input type="tel" className="field" value={currentState.phone} onChange={onChangePhone} />
                </div>
                <div className="contact-field">
                    <label className="field-label"htmlFor="name">City</label>
                    {/* <br></br> */}
                    <input type="text" className="field" value={currentState.city} onChange={onChangeCity} />
                </div>
                <div className="contact-field">
                    <label className="field-label" htmlFor="service">Service Type</label>
                    {/* <br></br> */}
                    <select name="service" id="service" className="field" value={currentState.service} onChange={onChangeService}>
                        {/* <option value="Residential">Residential</option> // TODO: get list of options from pest library data */}
                        {mapItemsToOptions(ServiceTypesItems)}                   
                    </select>
                </div>                
                <div className="contact-field">
                    <label className="field-label" htmlFor="bug">Pest Type</label>
                    {/* <br></br> */}
                    <select name="bug" id="bug" className="field" value={currentState.bug} onChange={onChangeBug}>
                    {mapItemsToOptions(BugTypesItems)} 
                    </select>
                </div>
                <div className="contact-field">
                    <label className="field-label" htmlFor="duration">Time of Emergence</label>
                    {/* <br></br> */}
                    <select name="duration" id="duration" className="field" value={currentState.duration} onChange={onChangeDuration}>
                    {mapItemsToOptions(DurationLengthsItems)} // TODO: get list of options from TP
                    </select>
                </div>
                <div className="contact-field">
                    <label className="field-label" htmlFor="body">Message</label>
                    {/* <br></br> */}
                    <textarea className="field" rows="5" onChange={onChangeBody}></textarea>
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
    )
}
