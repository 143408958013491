import { useState } from "react"
import "../styles/Contact.css"
import axios from 'axios'
import { ServiceTypesItems } from "../data/ContactDropdownItems"
export default function QueryContact(props){
    const initialState = {
        name: '',
        email: '',
        phone: '',
        body: '',
        service: 'Residential',
        
    }
    const [currentState, stateSetter] = useState(initialState);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios({
            method: "POST",
            url:"http://tridentpest.ca:3002/send",
            data:  currentState
          }).then((response)=>{
            if (response.data.status === 'success') {
              alert("Thank you for reaching out to us. Your message has been sent.");
            } else if (response.data.status === 'fail') {
              alert("Sorry, your message has not been sent. Please try again later.")
            }
          })
    }

    const mapItemsToOptions = (items) => {
        return items.map((item => <option value={item}>{item}</option>))
    }

    const onChangeName = (e) => {
        stateSetter({...currentState, name: e.target.value});
    }
    const onChangeEmail = (e) => {
        stateSetter({...currentState, email: e.target.value});
    }
    const onChangePhone = (e) => {
        stateSetter({...currentState, phone: e.target.value});
    }
    const onChangeBody = (e) => {
        stateSetter({...currentState, body: e.target.value});
    }
    const onChangeService = (e) => {
        stateSetter({ ...currentState, service: e.target.value });
    }

    return(
<div id="query-contact-section">
{/* <span className="quote-text"> Ask us your queries!</span>        */}
<div className="quote-text">
<span className="quote-text-normal">REQUEST A </span>
<span className="quote-text-underline">FREE</span>
<span className="quote-text-normal"> QUOTE!</span>
</div>
<div className="quote-subtext">
    <span className="quote-subtext-normal">CONTACT US AND GET A FREE QUOTE TODAY FROM OUR </span>
    <span className="quote-subtext-underline">CERTIFIED EXPERTS!</span>
</div>
<form className="query-contact"  method="POST" onSubmit={handleSubmit} id={props.id}>
    <div className="contact-field">
        <label className="field-label" htmlFor="name">Name</label>
        {/* <br></br> */}
        <input type="text" className="field" value={currentState.name} onChange={onChangeName} />
    </div>
    <div className="contact-field">
        <label className="field-label" htmlFor="email">Email address</label>
        {/* <br></br> */}
        <input type="email" className="field" value={currentState.email} onChange={onChangeEmail}/>
    </div>
    <div className="contact-field">
        <label className="field-label" htmlFor="phone">Phone</label>
        {/* <br></br> */}
        <input type="tel" className="field" value={currentState.phone} onChange={onChangePhone}/>
    </div>
    <div className="contact-field">
                    <label className="field-label" htmlFor="service">Service Type</label>
                    <select name="service" id="service" className="field" value={currentState.service} onChange={onChangeService}>
                        {mapItemsToOptions(ServiceTypesItems)}                   
                    </select>
                </div>  
    <div className="contact-field">
        <label className="field-label" htmlFor="body">Message</label>
        {/* <br></br> */}
        <textarea className="field" rows="5" onChange={onChangeBody}></textarea>
    </div>

    <button type="submit">Contact us!</button>
</form>
</div> 
)
}