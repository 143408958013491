export const SpecializationItems = [
    {
        name: "Cockroaches",
        desc: "Cockroaches are one of the most common pests in Canadian homes and businesses. Cockroaches can be a nuisance as they can contaminate food, utensils and preparation areas.",
        img_path:"specializations/cockroaches.jpg",
        link: "/library/rodents",
        hash: "#bug-info",
        buttonText: "Learn More"
    },
    {
        name: "Bed Bugs",
        desc: "They are usually found in hard to see and reach places. They travel easily and are transported via luggage, clothing and bedding.",
        img_path:"specializations/bedbugs.jpg",
        link: "/library/bedBugs",
        hash: "#bug-info",
        buttonText: "Learn More"
    },
    {
        name: "Mice",
        desc: "Rodents (rats & mice) are a serious issue that affects many residential, commercial and industrial facilities.  Not only do they spread diseases and contaminate food, they also cause damage to your property.",
        img_path:"specializations/mice.jpg",
        link: "/library/rodents",
        hash: "#bug-info",
        buttonText: "Learn More"
    }
]

