export const ServiceTypesItems = [
    'Residential',
    'Commercial',
    'Industrial'
];
export const BugTypesItems = [
    'Rodents',
    'Bed Bugs',
    'Cockroaches',
    'Flies',
    'Fleas',
    'Ants',
    'Carpenter Ants',
    'Pavement Ants',
    'Pharoah Ants',
    'Earwigs',
    'House Centipedes',
    'Millipedes',
    'Spiders',
    'Wasps',
    'Silverfish',
    'Other'
];

export const DurationLengthsItems = [
    '0-1 weeks',
    '2-3 weeks',
    '1-2 months',
    '3-6 months',
    'More than 6 months',
];