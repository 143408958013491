import { LazyLoadImage } from "react-lazy-load-image-component";
import Gallery from "./Gallery";

export default function SlideGallery(props) {
  return (
    <div className="slide-gallery">
              {props.slides.map ((slide, i) =>
            <div className='slide slide-list-img' key='i'>
               <div className="slide-img-container">
                {/* <img src={require("../images/" + props.img_folder + "/" + slide.img_path)} className='slide-img'/> */}
                <LazyLoadImage src={require("../images/" + props.img_folder + "/" + slide.img_path)} placeholderSrc={require("../images/" + props.img_folder + "/" + slide.img_path)} effect="blur" className='slide-img' draggable="false"/>
               {props.havetext && <div className="slide-text">{slide.text}</div>}
            </div>
            </div>
            )
        }
    </div>
  )
}
