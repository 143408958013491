// Text must be surrounded by backticks (`), not quotes (' or ") to preserve formatting. Be careful when editing text. 

export const SummaryHome = ['Welcome to the home of quality pest control services! We take care of all your Commercial, Residential, and Industrial pest control needs. Trident Pest Control is committed to providing the most comprehensive and accurate service at very affordable prices. We here at Trident Pest Control take a personal approach to resolving your problems. We work closely to businesses and homeowners alike to provide reliable and quality pest treatment and pest prevention programs.'];
export const SummaryHomeAbout=['We have over years of professional experience. So, you can be assured that your problems are in the right hands.'];
export const SummaryHomeResidential=['Getting rid of pests can be stressful. However, with our full comprehensive plans, it can be easy as A.B.C.'];
export const SummaryHomeContact=['If you have any Questions, comments or concerns, or you wish to book an appointment, click below to visit our contact section.']

export const SummaryAbout = ['Trident Pest Control is a reliable and qualified company that provides complete and experienced pest extermination services all throughout the GTA. Our story began in a humbler place. We built our reputation through word of mouth. From people, just like you. People who understood what quality service looks and feels like. No two situations are identical, so we spend time to understand your unique requirements and recommend the best treatment solution for you. We work closely with homeowners and businesses alike to provide reliable and quality pest treatment and pest prevention programs. Pest problems are stressful, and we give you peace of mind. With our same day service, you can trust that we will schedule a convenient time to assess and/or treat your pest problem quickly and efficiently.']
export const SummaryAboutList = [
    {
        point: 'Our Mission',
        summary: 'Our mission is to give you a thorough understanding of the problems you may face and an easy and reliable/affordable solution.'
    },
    {
        point: 'Our Vision',
        summary: 'Our vision is “Pest Control Made Easy”.'
    },
]
export const SummaryResidential = ['You want your home to be a safe and comfortable place to be – but not for pests! Our friendly technicians will quickly and efficiently identify the nature and severity of your pest problem and sit down with you to discuss your options. Having pests in your home can be a stressful experience, so we aim to minimise disruption to your family life. We’ll start by providing a quote that’s fair, competitive and accurate – with no hidden costs or added expenses. Once we’ve agreed on a plan of action that best suits your needs, our skilled pest control technicians will free your home from pests and safeguard against their return.']

export const SummaryResidentialTreatment = ['We here at Trident pest control pride ourselves on our ability to work both effectively and efficiently. We are expert exterminators that ensure that the job is done at the best quality with little to no interruption to your flow and quality of life. Unlike other companies our company make sure that we keep the pests out for good.']

export const SummaryResidentialAlpha = [
    'The Alpha plan provides you with complete and comprehensive coverage throughout the full year 365 days a year and emergency calls at any time. So, you can be assured that no matter what time and or place you may encounter a problem Trident Pest Control is there to safeguard against all of them. ',
    'We cover Cockroaches, Mice, Rats, Centipedes, Millipedes, Pavement Ants, Pill bugs, Sow bugs, Spiders, Fleas, Earwigs, Stored Product Pests, Carpenter Bees and WASPS!',
];

export const SummaryResidentialBravo = [
    'The Bravo plan provides complete and comprehensive coverage for seasonal pests for1 full season. We understand that severity of pest problems can range depending on the season we are in. Hence why our plan is to safeguard your home or business against any pests that may be a nuisance.',
    'We cover Pavement Ants, Cluster flies, Earwigs, Centipedes/Millipedes, Box elder bugs and Wasps/Hornets.'
];

export const SummaryResidentialCharlie = [
    'The Charlie plan provides coverage against single pests with a limited time warranty in homes or businesses. We understand that sometimes a single pest can cause a multitude of problems. We here at Trident Pest Control provide you with control methods that not only identify and inspect the severity of the problem but safeguard against their return. So, you can be carefree and not have to worry about pests in your home and or business.',
    'We cover Mice/Rats, Bed Bugs, Cockroaches, Carpenter Ants, Wasps/Hornets, and Stored Products Pests.'
];

export const SummaryResidentialABCTabs= [
    {
        name: 'A',
        content: SummaryResidentialAlpha
    },
    {
        name: 'B',
        content: SummaryResidentialBravo
    },
    {
        name: 'C',
        content: SummaryResidentialCharlie
    }
]

export const SummaryCommercial = [
    'Whatever your industry, pests are bad for business. Trident Pest Control Services will conduct a thorough inspection of your premises, identify the nature and severity of your pest problem and sit down with you to discuss your options.',
    'We’ll provide a fair, competitive and accurate quote – with no hidden costs. Once we’ve agreed on the plan that best suits your needs, our skilled technicians will act quickly to treat the problem, eradicating all pests and safeguarding against their return.',
    'Tailored specifically for your business, our pest control solutions are certified and cause minimal disruption to you, your staff and your customers. For food service industry clients, we’ll ensure your premises are pest-free so your business meets the rigorous standards of the relevant Food Authority.',
    'Our products are safe to use around people, keeping your staff and customers safe. We care about the environment, which means all our products come with the assurance of being environmentally friendly.'
]

