import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import '../styles/ButtonLink.css';
export default function ButtonLink(props){
    return <div className="button-link">
        {props.hash?
        // <Link to={{pathname: props.link, hash: props.hash}}>{props.text}</Link>
        <HashLink to={"" + props.link + props.hash}>{props.text}</HashLink>
        :
        <Link to={props.link}>{props.text}</Link>}
    </div>
}