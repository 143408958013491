import { LazyLoadImage } from "react-lazy-load-image-component";
import ButtonLink from "./ButtonLink";
import "../styles/FocusItem.css";
export default function FocusItem({item}) {
    return (<div className="focus-item-container">
    <div className="focus-item-image-container">
         <LazyLoadImage src={require("../images/" + item.img_path)} placeholderSrc={require("../images/placeholders/" + item.img_path)} effect="blur" className='focus-item-image' draggable="false"/>
    </div>
    <div className="focus-item-content">
        <div className="focus-item-title">
        <span>{item.name}</span>
        </div>

        <p className="focus-item-content-description">
            {item.desc}
        </p>
        <div className="focus-item-button-container">
        <ButtonLink link={item.link} text={item.buttonText} hash={item.hash}/>
        </div>
    </div>
</div>
);
}
