import Gallery from "./Gallery";
import { PestLibraryData } from "../data/PestLibraryData";
import Introducer from "./Introducer";
import ButtonLink from "./ButtonLink";
export default function PestLibrary() {
    return (
        <div id="library">
        <Gallery title='Pest Library' folder='library' items={PestLibraryData}></Gallery>
        <Introducer text= 'IF there are any questions that you may have about a pest that is not listed feel free to contact us for more information.' />
        <div className="more-info-contact" style={{backgroundColor: "rgba(128, 128, 128, 0.267)", paddingBottom: "2em"}}>
        <ButtonLink link="/" hash="#query-contact-section" text="Contact us"/>
        </div>
        
        </div>
    );
}